import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as accountApi from 'api/account';

export const getAccounts = createAsyncThunk(
  'account/getAccounts',
  async () => {
      const response = await accountApi.getAccounts();
      return { data: response.data.data };
  }
);

export const getUnassignAccountTokens = createAsyncThunk(
  'account/getUnassignAccountTokens',
  async () => {
      const response = await accountApi.getUnusedAccountToken();
      return { data: response.data.data };
  }
);

export const createAccount = createAsyncThunk(
  'account/createAccount',
  async (data) => {
      const response = await accountApi.createAccount(data);
      return { data: response.data.data };
  }
);

export const updateAccount = createAsyncThunk(
  'account/updateAccount',
  async (data) => {
      const response = await accountApi.updateAccount(data);
      return { data: response.data.data };
  }
);

export const archiveAccount = createAsyncThunk(
  'account/archiveAccount',
  async (data) => {
      const response = await accountApi.archiveAccount(data.id, data.status);
      return { data: response.data.data };
  }
);

export const accountRegistrations = createAsyncThunk(
  'account/accountRegistrations',
  async (id) => {
      const response = await accountApi.accountRegistrations(id);
      return { data: response.data.data, name: response.data.account_name, AccountToken: response.data.account_token};
  }
);

export const updateSiteLimit = createAsyncThunk(
  'account/updateSiteLimit',
  async (data) => {
      const response = await accountApi.updateSiteLimit(data);
      return { data: response.data.data};
  }
);

const initialState = {
  accounts: [],
  account: {},
  accountSitesTokens: [],
  accountName: '',
  accountToken: '',
  unassignAccountToken: [],
  error: null,
  loading: false,
  success: false
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetCreateAccount(state) {
      state.success = false;
    },
    selectAccount(state, {payload}) {
      state.account = state.accounts.find(account => account.id == payload)
    },
  },
  extraReducers: {
    [getAccounts.pending]: (state) => {
      state.loading = true;
    },
    [getAccounts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.accounts = payload.data;
    },
    [getAccounts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = null
    },
    [getUnassignAccountTokens.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.success = true;
      state.unassignAccountToken = payload.data;
    },
    [createAccount.pending]: (state) => {
      state.loading = true;
    },
    [createAccount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.accounts = [payload.data, ...state.accounts];
    },
    [updateAccount.pending]: (state) => {
      state.loading = true;
    },
    [updateAccount.fulfilled]: (state, { payload }) => {
      state.success = true;
      const newAccounts = state.accounts.filter(acount => acount.id != payload.data.id)
      state.loading = false;
      state.accounts = [payload.data, ...newAccounts];
    },
    [archiveAccount.pending]: (state) => {
      state.loading = true;
    },
    [archiveAccount.fulfilled]: (state, { payload }) => {
      const newAccounts = state.accounts.map(elem => {
        if (elem.id === payload.data.id) {
          return {
            ...elem,
            status: payload.data.status
          };
        }
        return elem;
      });
      state.accounts = newAccounts
      state.success = true;
      state.loading = false;
    },
    [accountRegistrations.pending]: (state) => {
      state.loading = true;
    },
    [accountRegistrations.fulfilled]: (state, { payload }) => {
      state.accountSitesTokens = payload.data;
      state.accountName = payload.name;
      state.accountToken = payload.AccountToken
      // state.success = true;
      state.loading = false;
    },
    [updateSiteLimit.pending]: (state) => {
      state.loading = true;
    },
    [updateSiteLimit.fulfilled]: (state, { payload }) => {
      const newAccounts = state.accounts.map(elem => {
        if (elem.id === payload.data.id) {
          return {
            ...elem,
            site_limit: payload.data.site_limit
          };
        }
        return elem;
      });
      state.accounts = newAccounts
      state.loading = false;
    },
  },
});

export const { resetCreateAccount, selectAccount } = accountSlice.actions;


export default accountSlice.reducer;