import React, { useEffect, useState } from "react";
import { useAuthDataContext } from "app/providers/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { getSiteId } from "slices/site";
import { Dropdown } from "semantic-ui-react";
import "./Header.scss";

const DesktopHeader = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, onLogout } = useAuthDataContext();
  const { siteId } = useSelector((state) => state.site);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    dispatch(getSiteId());
    const role = JSON.parse(localStorage.getItem("userRole"));
    const user = JSON.parse(localStorage.getItem("user"));
    setUserRole(role);
  }, []);

  const location = useLocation();
  const history = useHistory();
  const getClassName = (path) => {
    return location.pathname.includes(path) ? "active" : "";
  };
  return (
    <>
      <header id="header">
        <div className="header-top container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="logo">
            <Link to="#">
              <img src="/assets/images/logo-two.png" alt=""></img>
            </Link>
          </div>
          <Link
            className="logout"
            to="/login"
            onClick={() => {
              onLogout();
            }}
          >
            <i className="fas fa-sign-out-alt"></i> Logout
          </Link>
        </div>
        <div className="navbar">
          <nav className="container navbar-toggleable-sm">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className={getClassName("/profile")}>
                  <Link to="/profile">
                    <span>Profile</span>
                  </Link>
                </li>
                {userRole == "superadmin" && (
                  <li className={getClassName("/accounts")}>
                    <Link to="/accounts">
                      <span>Account Management</span>
                    </Link>
                  </li>
                )}
                {userRole == "superadmin" && (
                  <li className={getClassName("/blogs")}>
                    <Link to="/blogs">
                      <span>Blog Management</span>
                    </Link>
                  </li>
                )}
                {userRole == "admin" && (
                  <li className={getClassName("/sites")}>
                    <Link to="/sites">
                      <span>Sites</span>
                    </Link>
                  </li>
                )}
                {userRole == "admin" && (
                  <li className={getClassName("/users")}>
                    <Link to="/users">
                      <span>User Management</span>
                    </Link>
                  </li>
                )}
                {(userRole == "admin" || userRole == "candidate") && (
                  <li className={`nav-item dropdown ${getClassName("/leve")}`}>
                    <Dropdown text="CMMC Review">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => history.push("/levels/assessment")}
                          text="SPRS Assessment"
                        />
                        {siteId !== null && siteId !== undefined && (
                          <>
                            <Dropdown.Item
                              onClick={() =>
                                history.push(`/site/${siteId}/level-one`)
                              }
                              text="Level 1 (Foundational)"
                            />
                            <Dropdown.Item
                              onClick={() =>
                                history.push(`/site/${siteId}/level-two`)
                              }
                              text="Level 2 (Advanced)"
                            />
                            {/* <Dropdown.Item onClick={() => history.push(`/site/${siteId}/level-three`)} text='Level 3' /> */}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default DesktopHeader;
