import { combineReducers } from '@reduxjs/toolkit';
import userReducer from 'slices/user';
import profileReducer from 'slices/profile';
import accountReducer from 'slices/account';
import siteReducer from 'slices/site';
import usersReducer from 'slices/users';
import levelsReducer from 'slices/levels';
import levelTwoReducer from 'slices/levelTwo';
import levelThreeReducer from 'slices/levelThree';


const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  account: accountReducer,
  site: siteReducer,
  users: usersReducer,
  levels: levelsReducer,
  levelTwo: levelTwoReducer,
  levelThree: levelThreeReducer,
  
});

export default rootReducer;