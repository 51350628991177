import React, { useState } from "react";
import { Modal, Accordion, Icon } from "semantic-ui-react";

const Faq = ({ open, setOpen }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index;

    // this.setState({ activeIndex: newIndex })
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Modal
        closeIcon
        open={open}
        size={"large"}
        centered={false}
        onClose={() => setOpen(false)}
      >
        <Modal.Content>
          <div className="container">
            <div>
              <h2>Frequently Asked Questions</h2>
            </div>
            <Accordion styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What is Slice CMMC?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <p>
                  Slice CMMC is a centralized platform for organizations
                  involved in Cybersecurity Maturity Model Certification (CMMC)
                  implementation activities. Slice CMMC streamlines
                  collaboration, review, monitoring, and tracking of project
                  progress.
                </p>
                <p>
                  <b>Core Features:</b>
                </p>
                <ul>
                  <li>Centralized CMMC Level 1-3 Implementation Monitoring</li>
                  <li>Slice System Security Plan Template</li>
                  <li>NIST 800-171 Self Assessment Tool </li>
                  <li>Single or Multi-Site Management</li>
                  <li>
                    Ease of Collaboration through Role-based User Provisioning
                  </li>
                </ul>
                <p>
                  <b>
                    Slice CMMC is flexible and may be configured to meet the
                    needs of the following types of organizations:
                  </b>
                </p>
                <ul>
                  <li>Cybersecurity and Managed IT Consultants</li>
                  <li>Defense Contractors</li>
                  <li>Federally Funded Institutions</li>
                </ul>
                <p>
                  For more information email our team at contact@slice-cmmc.com
                </p>
              </Accordion.Content>
              {/* 
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <Icon name='dropdown' />
                  What is Slice QMS?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                    Slice QMS is a cloud-based quality management system for small to medium manufacturers. Slice Quality Management System (QMS) simplifies, structures, and accelerates the internal part inspection, customer and regulatory audits, and day-to-day monitoring of quality systems. QMS provides a single source of truth for quality that reduces the stress and burden of maintaining regulatory standards.
                  </p>
                  <p></p>
                </Accordion.Content> */}

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What is CMMC?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p>
                  <b>
                    CMMC stands for Cybersecurity Maturity Model Certification.
                  </b>
                  The aim of this initiative is to require prime and sub-prime
                  defense contractors to demonstrate foundational levels of
                  cybersecurity implementation. Cybersecurity is becoming
                  increasingly important as the wave of 5G connectivity expands
                  usage of cellular networks to sensors, edge devices, and
                  machine equipment affecting the industrial base.
                </p>
                <p>
                  In 2021, contractors stand to lose the opportunity to be
                  awarded over $360 billion in Defense contracts. CMMC is
                  intended to be incorporated into the Defense Federal
                  Acquisition Regulation Supplement (DFARS) making it a
                  requirement to be awarded a contract.
                  <b>
                    As a defense contractor, CMMC is a must have certification
                    for business continuity. As early as September 2020, new
                    RFI/RFPs will require both prime contractors and
                    sub-contractors are expected to be certified.
                  </b>
                </p>
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What are the 3 CMMC Levels?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p>
                  CMMC consists of 3 levels aligning with the NIST SP 800:171.
                  The 3 CMMC Levels are listed below:
                </p>
                <ol>
                  <li>Level 1: Foundational (Performed Process) </li>
                  <li>Level 2: Advanced (Managed / Documented Process)</li>
                  <li>Level 3: Expert (Reviewed / Optimizing Process)</li>
                </ol>
                <p>
                  NIST SP 800:171 Special Publication that provides recommended
                  requirements for protecting the confidentiality of controlled
                  unclassified information (CUI). Small businesses working with
                  the DoD are expected to certify to Level 1 and 2. Larger
                  government prime contractors and OEMs will be expected to
                  obtain Level 3 certification.
                </p>
              </Accordion.Content>
              {/* <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What level certification does my organization need to meet?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <p>
                  Subcontractors will be expected demonstrate between Level 1
                  and Level 3 certification and prime contractors between Level
                  4 and 5.
                </p>
                <p>
                  Levels 1-2 are for companies that manage data on-premise.
                  Levels 3-5 are for companies that have cloud-based operations.
                  At Levels 4-5 contractors must reduce risk of Advanced
                  Persistent Threats (APT). APT adversaries achieve objectives
                  through multiple attack vectors (cyber, physical, deception).
                </p>
              </Accordion.Content> */}
              <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What is NIST 800-171 Revision 2?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5}>
                <p>
                  Prior to CMMC, the National Institute of Standards and
                  Technology (NIST) published 800-171 R2 as a cybersecurity
                  standard for non-government organizations sharing, collecting,
                  processing, storing, or transmitting{" "}
                  <b>
                    Controlled Unclassified Information (CUI) and Federal
                    Contract Information (FCI)
                  </b>
                  . Examples of CUI include contracts, electronic files,
                  blueprints, 3D CAD models, drawings, and email.
                </p>
                <p>
                  As of December 1, 2020, DFARS 252.204.7019 is in effect. This
                  rule requires contractors to submit a risk score to the
                  Supplier Performance Risk System (SRPS) based on NIST 800-171
                  assessment in order to bid of defense contracts.
                </p>
                <p>
                  CMMC is a rewording and structuring of NIST 800-171 and other
                  standards to be more practical and auditable by a third-party
                  assessor.
                </p>
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex === 6}
                index={6}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                What are other sources of information about CMMC?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 6}>
                <p>CMMC Model: https://www.acq.osd.mil/cmmc/draft.html</p>
                <p>
                  CMMC Accreditation Board: https://www.cmmcab.org/cmmc-standard
                </p>
                <p>
                  Department of Defense CUI Toolkit:
                  https://www.cdse.edu/toolkits/cui/index.html
                </p>
                <p>
                  <b>If you have additional questions, email us.</b>
                </p>
                <p>contact@slice-cmmc.com</p>
                <p>
                  Slice CMMC is a product of PII Technologies, LLC based in
                  Roanoke, VA, USA.
                </p>
              </Accordion.Content>
            </Accordion>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Faq;
