import { lazy } from "react";

export default [
  {
    path: "/register",
    public: true,
    exact: true,
    component: lazy(() => import("views/User/Register")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/payment/:id",
    public: false,
    exact: true,
    component: lazy(() => import("views/User/Payment/Payment")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/subscribe",
    public: true,
    exact: true,
    component: lazy(() => import("views/User/Payment/Pricing_Offers")),
    restricted: false,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/home",
    public: true,
    exact: true,
    component: lazy(() => import("views/Home/Home")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/",
    public: true,
    exact: true,
    component: lazy(() => import("views/Home/Home")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/login",
    public: true,
    exact: true,
    component: lazy(() => import("views/User/Login/Login")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/forgot-password",
    public: true,
    exact: true,
    component: lazy(() => import("views/User/ForgetPassword/ForgetPassword")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/reset-password",
    public: true,
    exact: true,
    component: lazy(() => import("views/User/ResetPassword/ResetPassword")),
    restricted: true,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/profile",
    public: false,
    exact: true,
    component: lazy(() => import("views/Profile/Profile")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/accounts",
    public: false,
    exact: true,
    component: lazy(() => import("views/Accounts/Accounts")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/blogs",
    public: false,
    exact: true,
    component: lazy(() => import("views/Blog/BlogList")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/sites",
    public: false,
    exact: true,
    component: lazy(() => import("views/Sites/Sites")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/users",
    public: false,
    exact: true,
    component: lazy(() => import("views/Users/Users")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/level-one",
    public: false,
    exact: true,
    component: lazy(() => import("views/Levels/LevelOne")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/site/:id/level-one",
    public: false,
    exact: true,
    component: lazy(() => import("views/Levels/LevelOne")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/site/:id/level-two",
    public: false,
    exact: true,
    component: lazy(() => import("views/Levels/LevelTwo")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/site/:id/level-three",
    public: false,
    exact: true,
    component: lazy(() => import("views/Levels/LevelThree")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/levels/assessment",
    public: false,
    exact: true,
    component: lazy(() => import("views/Assessment/Assessment")),
    showHeader: true,
    showFooter: false,
  },
  {
    path: "/blog",
    public: true,
    exact: true,
    component: lazy(() => import("views/Blog/Blog")),
    restricted: false,
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/blogDetail/:id",
    public: true,
    exact: true,
    component: lazy(() => import("views/Blog/BlogDetail")),
    restricted: false,
    showHeader: false,
    showFooter: false,
  },
];
