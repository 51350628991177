import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as levelsApi from 'api/levels';

export const levelTwo = createAsyncThunk(
  'levelTwo/levelTwo',
  async (id) => {
      const response = await levelsApi.levelTwo(id);
      return { data: response.data.data };
  }
);

export const getSites = createAsyncThunk(
  'levelTwo/getSites',
  async (id) => {
      const response = await levelsApi.getSites();
      return { data: response.data.data };
  }
);

export const updatePractice = createAsyncThunk(
  'levelTwo/updatePractice',
  async (data) => {
      const response = await levelsApi.updatePractice(data);
      return { data: response.data.data };
  }
);

export const updateComment = createAsyncThunk(
  'levelTwo/updateComment',
  async (data) => {
      const response = await levelsApi.updateComment(data);
      return { data: response.data.data };
  }
);

export const addLevelFile = createAsyncThunk(
  'levelTwo/addLevelFile',
  async (data) => {
      const response = await levelsApi.addFile(data);
      return { data: response.data.data };
  }
);

const handleUpdateLevelType = (practices, payload) => {
    let newAcdPratices = practices.map(elem => {
      if (elem.practice_target_id === payload.data.practice_target_id) {
        return {
          ...elem,
          status: payload.data.status,
          target_implementation_date: payload.data.target_implementation_date,
          target_implementation_date_end: payload.data.target_implementation_date_end,
          completion_date: payload.data.completion_date,
          practice_owner: payload.data.practice_owner,
          comment: payload.data.comment

        };
      }
      return elem;
    })
  return newAcdPratices
}

const initialState = {
  levelTwoData: {},
  acPractices: [],
  auPractices: [],
  atPractices: [],
  cmPractices: [],
  iaPractices: [],
  irPractices: [],
  maPractices: [],
  mpPractices: [],
  psPractices: [],
  pePractices: [],
  rePractices: [],
  rmPractices: [],
  caPractices: [],
  scPractices: [],
  siPractices: [],
  ltdPractices: [],
  fileUrls: [],
  comment: '',
  sites: [],
  error: null,
  fileUploaded: false,
  loading: false,
  success: false
};

const levelTwoSlice = createSlice({
  name: 'levelTwo',
  initialState,
  reducers: {
    resetFileUpload(state) {
      state.success = false;
      state.fileUploaded = false;
    },
    deleteFile(state, {payload}) {
      state.fileUrls = state.fileUrls.filter(n => n.file_id !== payload)
    },
  },
  extraReducers: {
    [levelTwo.pending]: (state) => {
      state.loading = true;
    },
    [levelTwo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.levelTwoData = payload.data;
      state.fileUrls = payload.data.level_file_url;
      state.acPractices = payload.data.ac_practices
      state.auPractices = payload.data.au_practices
      state.atPractices = payload.data.at_practices
      state.cmPractices = payload.data.cm_practices
      state.iaPractices = payload.data.ia_practices
      state.irPractices = payload.data.ir_practices
      state.maPractices = payload.data.ma_practices
      state.mpPractices = payload.data.mp_practices
      state.psPractices = payload.data.ps_practices
      state.pePractices = payload.data.pe_practices
      state.rePractices = payload.data.re_practices
      state.rmPractices = payload.data.rm_practices
      state.caPractices = payload.data.ca_practices
      state.scPractices = payload.data.sc_practices
      state.siPractices = payload.data.si_practices
      state.ltdPractices = payload.data.ltd_practices
      state.comment = payload.data.comment
      
    },
    [levelTwo.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = null
    },
    [getSites.pending]: (state) => {
      state.loading = true;
    },
    [getSites.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sites = payload.data;
    },
    [updatePractice.pending]: (state) => {
      state.loading = true;
    },
    [updatePractice.fulfilled]: (state, { payload }) => {

      let type = payload.data.practice_type
      switch (type) {
        case "AC":
          const newAcPratices = state.acPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.acPractices = newAcPratices
          state.levelTwoData.ac_verified = payload.data.ac_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "AU":
          const newAuPratices = state.auPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.auPractices = newAuPratices
          state.levelTwoData.au_verified = payload.data.au_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "AT":
          const newAtPratices = state.atPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.atPractices = newAtPratices
          state.levelTwoData.at_verified = payload.data.at_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "CM":
          const newCmPratices = state.cmPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.cmPractices = newCmPratices
          state.levelTwoData.cm_verified = payload.data.cm_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "IA":
          const newIaPratices = state.iaPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.iaPractices = newIaPratices
          state.levelTwoData.ia_verified = payload.data.ia_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "IR":
          const newIrPratices = state.irPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.irPractices = newIrPratices
          state.levelTwoData.ir_verified = payload.data.ir_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "MA":
          state.maPractices = handleUpdateLevelType(state.maPractices, payload)
          state.levelTwoData.ma_verified = payload.data.ma_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "MP":
          state.maPractices = handleUpdateLevelType(state.mpPractices, payload)
          state.levelTwoData.mp_verified = payload.data.mp_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "PS":
          state.psPractices = handleUpdateLevelType(state.psPractices, payload)
          state.levelTwoData.ps_verified = payload.data.ps_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "PE":
          state.pePractices = handleUpdateLevelType(state.pePractices, payload)
          state.levelTwoData.pe_verified = payload.data.pe_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "RE":
          state.rePractices = handleUpdateLevelType(state.rePractices, payload)
          state.levelTwoData.re_verified = payload.data.re_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "RM":
          state.rmPractices = handleUpdateLevelType(state.rmPractices, payload)
          state.levelTwoData.rm_verified = payload.data.rm_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "CA":
          state.caPractices = handleUpdateLevelType(state.caPractices, payload)
          state.levelTwoData.ca_verified = payload.data.ca_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "SC":
          state.scPractices = handleUpdateLevelType(state.scPractices, payload)
          state.levelTwoData.sc_verified = payload.data.sc_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "SI":
          state.siPractices = handleUpdateLevelType(state.siPractices, payload)
          state.levelTwoData.si_verified = payload.data.si_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        case "LTD":
          state.ltdPractices = handleUpdateLevelType(state.ltdPractices, payload)
          state.levelTwoData.ltd_verified = payload.data.ltd_verified
          state.levelTwoData.not_palnned = payload.data.not_palnned
          state.levelTwoData.panned = payload.data.panned
          state.levelTwoData.in_pregress = payload.data.in_pregress
          state.levelTwoData.completed = payload.data.completed
          state.levelTwoData.verified = payload.data.verified
          state.loading = false;
          break;
        default:
           return null
      }
    },
    
    [updateComment.pending]: (state) => {
      state.loading = true;
    },
    [updateComment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.comment = payload.data.comment
        
    },
    [addLevelFile.pending]: (state) => {
      state.loading = true;
    },
    [addLevelFile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fileUrls = payload.data.level_file_url
      state.success = true;
      state.fileUploaded = true;
        
    },
  },
});

export const { resetFileUpload, deleteFile } = levelTwoSlice.actions;


export default levelTwoSlice.reducer;