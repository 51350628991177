import * as Request from 'utils/Request'

export const getAccountUsers = async () => {
  const url = await `${Request.baseUrl}/api/v1/users/account_users`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const getSites = async () => {
  const url = await `${Request.baseUrl}/api/v1/sites/account_sites`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const createUser = async (data) => {
  const url = await `${Request.baseUrl}/api/v1/users/create_new_user`
  const headers = await Request.authHeader()
  const res = await Request.POST(url, data, headers)
  return res
 }

 export const UpdateSiteUser = async (data) => {
  const url = await `${Request.baseUrl}/api/v1/users/${data.id}/update_site_user`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, data, headers)
  return res
 }