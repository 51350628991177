import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { logoutUser } from "slices/user";
import { useDispatch } from "react-redux";

export const AuthDataContext = createContext();
const initialAuthData = {};

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [authData, setAuthData] = useState(initialAuthData);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !JSON.parse(localStorage.getItem("user")) ? false : true
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, [isAuthenticated]);

  const checkIsAuthenticated = () => {
    const rawAuthData = JSON.parse(localStorage.getItem("user"));
    const rawTokensData = JSON.parse(localStorage.getItem("token"));

    if (!rawTokensData) return Promise.reject(new Error("Unauthenticated"));
    if (!rawAuthData) return Promise.reject(new Error("Unauthenticated"));
    return Promise.resolve(rawAuthData);
  };

  const checkAuth = () =>
    checkIsAuthenticated()
      .then(setAuthData)
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false))
      .finally(() => {
        setIsLoading(false);
      });

  // Load auth data from local storage if it exists
  useEffect(() => {
    checkAuth();
  }, []);

  const onLogout = () => {
    dispatch(logoutUser());
    setAuthData(initialAuthData);
    setIsAuthenticated(false);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
  };

  const onLogin = () => {
    const newAuthData = localStorage.getItem("user");
    setIsAuthenticated(true);
    if (newAuthData) {
      setAuthData(JSON.parse(newAuthData));
    }
  };

  const authDataValue = useMemo(
    () => ({ ...authData, isLoading, onLogin, onLogout, isAuthenticated }),
    [authData, isLoading, isAuthenticated]
  );

  return (
    <AuthDataContext.Provider value={authDataValue}>
      {children}
    </AuthDataContext.Provider>
  );
};

export const useAuthDataContext = () => useContext(AuthDataContext);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
