import * as Request from 'utils/Request'


export const getSites = async () => {
  const url = await `${Request.baseUrl}/api/v1/sites`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const getUnusedSiteTokens = async () => {
  const url = await `${Request.baseUrl}/api/v1/sites/unassign_site_tokens`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
}

export const createSite = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/sites`
  const headers = await Request.authHeader()
  const res = await Request.POST(url, body, headers)
  return res
 }

 export const updateSite = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${body.id}`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  return res
 }

 export const archiveSite = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${id}/archive_site`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const getSiteId = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/get_site_id`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const siteUsers = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${id}/site_users`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }