import * as Request from "utils/Request";

export const register = async (body) => {
  // try {
  const url = `${Request.baseUrl}/api/v1/user/sign_up`;
  const headers = Request.authHeader();
  const res = await Request.POST(url, body, headers);
  console.log("response is", res);
  return res.data;
  // } catch (error) {
  //   return error.response.data
  // }
};

export const login = async (body) => {
  console.log("beshrm chl jao");
  const url = `${Request.baseUrl}/api/v1/user/sign_in`;
  console.log(url);
  const headers = Request.authHeader();
  const res = await Request.POST(url, body, headers);

  return res;
};

export const changePassword = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/user/change_password`;
  const headers = await Request.authHeader();
  const res = await Request.PUT(url, body, headers);
  return res;
};

export const profile = async () => {
  const url = await `${Request.baseUrl}/api/v1/users/profile`;
  const headers = await Request.authHeader();
  const res = await Request.GET(url, headers);
  return res;
};

export const updateProfile = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/user/update`;
  const headers = await Request.authHeader();
  const res = await Request.PUT(url, body, headers);
  return res;
};

export const forgetPassword = async (body) => {
  try {
    const url = `${Request.baseUrl}/api/v1/user/forget`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (body) => {
  try {
    const url = `${Request.baseUrl}/api/v1/user/reset`;
    const headers = Request.authHeader();
    const res = await Request.PUT(url, body, headers);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
