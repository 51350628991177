import React, {useState} from 'react';
import TermsOfService from 'components/Modals/TermsOfService'
import Faq from 'components/Modals/Faq'
import ContactUs from 'components/Modals/ContactUs'

const Footer = () => {
  const [openTS, setOpenTS] = useState(false)
  const [openFaq, setOpenFaq] = useState(false)
  const [openCU, setOpenCU] = useState(false)
  return (
    <>
    {openCU && <ContactUs open={openCU} setOpen={setOpenCU} />} 
    {openTS && <TermsOfService open={openTS} setOpen={setOpenTS} />}
    {openFaq && <Faq open={openFaq} setOpen={setOpenFaq} />}
    <div className="login-footer">
      <ul className="footer-link">
        <li><span onClick={() => setOpenCU(true)}>Contact Us</span></li>
        <li><span onClick={() => setOpenFaq(true)}>FAQ</span></li>
        <li><span onClick={() => setOpenTS(true)}>Terms & Conditions</span></li>
      </ul>
    </div>
    </>
  )
}

export default Footer;