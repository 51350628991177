import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as UserAPI from "api/user";
import * as tokenAPI from "api/accountRegistration";

export const userProfile = createAsyncThunk(
  "profile/userprofile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.profile(data);
      return { data: response.data.data, sites: response.data.sites };
    } catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.changePassword(data);
      return { data: response.data.data };
    } catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.updateProfile(data);
      return { data: response.data.data };
    } catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

export const getAccountTokens = createAsyncThunk(
  "profile/getAccountTokens",
  async (data, { rejectWithValue }) => {
    try {
      const response = await tokenAPI.getAccountRegistrationCode();
      return { data: response.data.data };
    } catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

export const createAccountToken = createAsyncThunk(
  "profile/createAccountToken",
  async (data) => {
    const response = await tokenAPI.createNewAccountToken();
    return { data: response.data.data };
  }
);

export const getSiteTokens = createAsyncThunk(
  "profile/getSiteTokens",
  async (data) => {
    const response = await tokenAPI.getsAccountRegistrationTokens();
    return { data: response.data.data };
  }
);

export const createSiteToken = createAsyncThunk(
  "profile/createSiteToken",
  async (data) => {
    const response = await tokenAPI.createNewSiteToken();
    return { data: response.data.data };
  }
);

const initialState = {
  profile: {
    email: "",
    firstName: "",
    LastName: "",
    phone_number: "",
    registation_number: "",
    company: "",
    title: "",
    images: {},
  },
  changePassword: {
    passwordErrorMessage: "",
    loading: false,
    error: null,
    success: false,
  },
  accountTokens: {
    data: [],
    error: null,
    loading: false,
  },
  siteTokens: [],
  sites: [],
  error: null,
  loading: false,
  success: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetpasswordErrorMessage(state) {
      state.changePassword.error = null;
    },
    resetEditUser(state) {
      state.success = false;
    },
  },
  extraReducers: {
    [userProfile.pending]: (state) => {
      state.loading = true;
    },
    [userProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload.data;
      state.sites = payload.sites;
    },
    [userProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      state.login.error = payload.data.errors;
    },
    [changePassword.pending]: (state) => {
      state.changePassword.loading = true;
      state.changePassword.success = false;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.changePassword.loading = false;
      state.changePassword.success = true;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.changePassword.loading = false;
      state.changePassword.error = payload.data.errors;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload.data;
      state.success = true;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.data.errors;
    },
    [getAccountTokens.pending]: (state) => {
      state.accountTokens.loading = true;
    },
    [getAccountTokens.fulfilled]: (state, { payload }) => {
      state.accountTokens.loading = false;
      state.accountTokens.data = payload.data;
    },
    [getAccountTokens.rejected]: (state, { payload }) => {
      state.accountTokens.loading = false;
      state.accountTokens.error = payload.data.errors;
    },
    [createAccountToken.fulfilled]: (state, { payload }) => {
      state.accountTokens.data = [payload.data, ...state.accountTokens.data];
    },
    [getSiteTokens.fulfilled]: (state, { payload }) => {
      state.siteTokens = payload.data;
    },
    [createSiteToken.fulfilled]: (state, { payload }) => {
      state.siteTokens = [payload.data, ...state.siteTokens];
    },
  },
});

export const { resetpasswordErrorMessage, resetEditUser } =
  profileSlice.actions;

export default profileSlice.reducer;
