import React from "react";
import { Modal } from "semantic-ui-react";

const TermsOfService = ({ open, setOpen }) => {
  return (
    <>
      <Modal
        closeIcon
        open={open}
        size={"large"}
        centered={false}
        onClose={() => setOpen(false)}
      >
        <Modal.Content>
          <div className="container terms-service">
            <div>
              <h2>Terms of Service</h2>
              <p>
                <b>Last updated:</b> December 3, 2020
              </p>
              <p>
                Please read these Terms and Conditions (“Terms”, “Terms and
                Conditions”) carefully before using the www.slice-cmmc.com
                website or subdomain (the “Service”) operated by PII
                Technologies, LLC (“us”, “we”, or “our”).
              </p>
              <p>
                Your access to and use of the Service is conditioned upon your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users and others who wish to access or use the
                Service.
              </p>
              <p>
                By accessing or using the Service you agree to be bound by these
                Terms. If you disagree with any part of the terms then you do
                not have permission to access the Service.
              </p>
            </div>
            <div>
              <h3>Communications</h3>
              <p>
                By creating an Account on our service, you agree to subscribe to
                newsletters, marketing or promotional materials and other
                information we may send. However, you may opt out of receiving
                any, or all, of these communications from us by following the
                unsubscribe link or instructions provided in any email we send.
              </p>
            </div>
            <div>
              <h3>Subscriptions</h3>
              <p>
                Some parts of the Service are billed on a subscription basis
                (“Subscription(s)”). You will be billed in advance on a
                recurring and periodic basis (“Billing Cycle”). Billing cycles
                are set on an annual basis.
              </p>
              <p>
                At the end of each Billing Cycle, your Subscription will
                automatically renew under the exact same conditions unless you
                cancel it or PII Technologies, LLC cancels it. You may cancel
                your Subscription renewal either by contacting PII Technologies,
                LLC customer support team.
              </p>
              <p>
                A valid payment method, including credit card, is required to
                process the payment for your Subscription. You shall provide PII
                Technologies, LLC with accurate and complete billing information
                including full name, address, state, zip code, telephone number,
                and a valid payment method information. By submitting such
                payment information via PayPal, you automatically authorize PII
                Technologies, LLC to charge all Subscription fees incurred
                through your account to any such payment instruments.
              </p>
              <p>
                Should automatic billing fail to occur for any reason, PII
                Technologies, LLC will issue an electronic invoice indicating
                that you must proceed manually, within a certain deadline date,
                with the full payment corresponding to the billing period as
                indicated on the invoice.
              </p>
            </div>
            <div>
              <h3>Customizations for Enterprise</h3>
              <p>
                PII Technologies, LLC may bill for customizations, as well as a
                standalone enterprise deployment of the application for
                enterprise customers. Customizations will be billed via a
                one-time implementation fee and an annual maintenance fee.
              </p>
              <p>
                PII Technologies shall notify you if a (i) customization
                maintenance fee is modified, or (ii) customization is no longer
                supported.
              </p>
            </div>
            <div>
              <h3>Fee Changes</h3>
              <p>
                PII Technologies, LLC in its sole discretion and at any time,
                may modify the Subscription fees for the Subscriptions. Any
                Subscription fee change will become effective at the end of the
                then-current Billing Cycle.
              </p>
              <p>
                PII Technologies, LLC will provide you with a reasonable prior
                notice of any change in Subscription fees to give you an
                opportunity to terminate your Subscription before such change
                becomes effective.
              </p>
              <p>
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes your agreement to pay the
                modified Subscription fee amount.
              </p>
            </div>
            <div>
              <h3>Refunds</h3>
              <p>
                PII Technologies, LLC. does not offer refunds on Subscription
                products after account usage. The team shall work with you
                address any concerns.
              </p>
            </div>
            <div>
              <h3>Content</h3>
              <p>
                Our Service allows you to post, link, store, share and otherwise
                make available certain information, text, graphics, videos,
                reports, or other material (“Content”). You are responsible for
                the Content that you post on or through the Service, including
                its legality, reliability, and appropriateness.
              </p>
              <p>
                By posting Content on or through the Service, You represent and
                warrant that: (i) the Content is yours (you own it) and/or you
                have the right to use it and the right to grant us the rights
                and license as provided in these Terms, and (ii) that the
                posting of your Content on or through the Service does not
                violate the privacy rights, publicity rights, copyrights,
                contract rights or any other rights of any person or entity. We
                reserve the right to terminate the account of anyone found to be
                infringing on a copyright.
              </p>
              <p>
                You retain any and all of your rights to any Content you submit,
                post or display on or through the Service and you are
                responsible for protecting those rights. We take no
                responsibility and assume no liability for Content you or any
                third party posts on or through the Service.
              </p>
              <p>
                PII Technologies, LLC has the right but not the obligation to
                monitor and edit all Content provided by users.
              </p>
              <p>
                In addition, Content found on or through this Service are the
                property of PII Technologies, LLC or used with permission. You
                may not distribute, modify, transmit, reuse, download, repost,
                copy, or use said Content, whether in whole or in part, for
                commercial purposes or for personal gain, without express
                advance written permission from us.
              </p>
            </div>
            <div>
              <h3>Accounts</h3>
              <p>
                When you create an account with us, you guarantee that you are
                above the age of 18, and that the information you provide us is
                accurate, complete, and current at all times. Inaccurate,
                incomplete, or obsolete information may result in the immediate
                termination of your account on the Service.
              </p>
              <p>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>
            </div>
            <div>
              <h3>Intellectual Property</h3>
              <p>
                The Service and its original content (excluding Content provided
                by users), features and functionality are and will remain the
                exclusive property of PII Technologies, LLC. and its licensors.
                The Service is protected by copyright, trademark, and other laws
                of both the United States and foreign countries. Our trademarks
                and trade dress may not be used in connection with any product
                or service without the prior written consent of PII
                Technologies, LLC.
              </p>
            </div>
            <div>
              <h3>Links To Other Web Sites</h3>
              <p>
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by PII Technologies,
                LLC.
              </p>
              <p>
                PII Technologies, LLC. has no control over, and assumes no
                responsibility for the content, privacy policies, or practices
                of any third party web sites or services. We do not warrant the
                offerings of any of these entities/individuals or their
                websites.
              </p>
              <p>
                You acknowledge and agree that PII Technologies, LLC shall not
                be responsible or liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with
                use of or reliance on any such content, goods or services
                available on or through any such third party web sites or
                services.
              </p>
              <p>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third party web sites or services that
                you visit.
              </p>
            </div>
            <div>
              <h3>Termination</h3>
              <p>
                We may terminate or suspend your account and bar access to the
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of the Terms.
              </p>
              <p>
                If you wish to terminate your account, you may simply
                discontinue using the Service.
              </p>
              <p>
                All provisions of the Terms which by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </p>
            </div>
            <div>
              <h3>Indemnification</h3>
              <p>
                You agree to defend, indemnify and hold harmless PII
                Technologies, LLC. and its licensee and licensors, and their
                employees, contractors, agents, officers and directors, from and
                against any and all claims, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including but not
                limited to attorney’s fees), resulting from or arising out of a)
                your use and access of the Service, by you or any person using
                your account and password; b) a breach of these Terms, or c)
                Content posted on the Service.
              </p>
            </div>
            <div>
              <h3>Limitation Of Liability</h3>
              <p>
                In no event shall PII Technologies, LLC nor its directors,
                employees, partners, agents, suppliers, or affiliates, be liable
                for any indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the
                Service; (ii) any conduct or content of any third party on the
                Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence) or any other legal theory, whether or not we have
                been informed of the possibility of such damage, and even if a
                remedy set forth herein is found to have failed of its essential
                purpose.
              </p>
            </div>
            <div>
              <h3>Disclaimer</h3>
              <p>
                Your use of the Service is at your sole risk. The Service is
                provided on an “AS IS” and “AS AVAILABLE” basis. The Service is
                provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>
              <p>
                PII Technologies, LLC its subsidiaries, affiliates, and its
                licensors do not warrant that a) the Service will function
                uninterrupted, secure or available at any particular time or
                location; b) any errors or defects will be corrected; c) the
                Service is free of viruses or other harmful components; or d)
                the results of using the Service will meet your requirements.
              </p>
            </div>
            <div>
              <h3>Exclusions</h3>
              <p>
                Some jurisdictions do not allow the exclusion of certain
                warranties or the exclusion or limitation of liability for
                consequential or incidental damages, so the limitations above
                may not apply to you.
              </p>
            </div>
            <div>
              <h3>Governing Law</h3>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws of Delaware, United States, without regard to its
                conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service, and supersede and replace any prior
                agreements we might have had between us regarding the Service.
              </p>
            </div>
            <div>
              <h3>Changes</h3>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will provide at least 30 days notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>
              <p>
                By continuing to access or use our Service after any revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, you are no longer authorized
                to use the Service.
              </p>
            </div>
            <div>
              <h3>Contact Us</h3>
              <p>
                If you have any questions about these Terms, please contact us
                at contact@cmmc-review.com
              </p>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default TermsOfService;
