/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "views/Layout/Layout";
import { useAuthDataContext } from "app/providers/AuthProvider";

const PrivateRoute = ({
  component: Component,
  headingComponent: HeadingComponent,
  showHeader = true,
  showFooter = true,
  ...options
}) => {
  const { isAuthenticated } = useAuthDataContext();

  const shouldRedirect = !isAuthenticated;

  const renderPrivateRoute = (props) => {
    // if (isLoading) return <Spinner />;

    console.log("Private routes", isAuthenticated);

    if (shouldRedirect) return <Redirect to="/home" />;

    return (
      <Layout showHeader={showHeader} showFooter={showFooter}>
        {HeadingComponent && <HeadingComponent {...props} />}
        <Component {...props} />
      </Layout>
    );
  };

  return <Route {...options} render={(props) => renderPrivateRoute(props)} />;
};

export default PrivateRoute;
