import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as usersApi from 'api/users';

export const getAccountUsers = createAsyncThunk(
  'users/getAccountUsers',
  async () => {
      const response = await usersApi.getAccountUsers();
      return { data: response.data };
  }
);

export const getSites = createAsyncThunk(
  'users/getSites',
  async () => {
      const response = await usersApi.getSites();
      return { data: response.data.data };
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersApi.createUser(data);
      return { data: response.data.data };
    }catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

export const updateSiteUser = createAsyncThunk(
  'users/updateSiteUser',
  async (data) => {
      const response = await usersApi.UpdateSiteUser(data);
      return { data: response.data.data };
  }
);


const initialState = {
  administratorUsers: [],
  siteUsers: [],
  accountName: '',
  user: {},
  sites: [],
  error: null,
  loading: false,
  success: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetModal(state) {
      state.success = false;
    },
    resetError(state) {
      state.error = null
    },
    selectUser(state, {payload}) {
      state.user = state.siteUsers.find(user => user.id == payload)
    },
  },
  extraReducers: {
    [getAccountUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAccountUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.administratorUsers = payload.data.administrators;
      state.siteUsers = payload.data.site_users;
      state.accountName = payload.data.account_name
    },
    [getAccountUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = null
    },
    [getSites.pending]: (state) => {
      state.loading = true;
    },
    [getSites.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sites = payload.data;
    },
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.siteUsers = [payload.data, ...state.siteUsers];
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.data.errors[0]
    },
    [updateSiteUser.pending]: (state) => {
      state.loading = true;
    },
    [updateSiteUser.fulfilled]: (state, { payload }) => {
      state.success = true;
      const newUsers = state.siteUsers.filter(user => user.id != payload.data.id)
      state.loading = false;
      state.siteUsers = [payload.data, ...newUsers];
    },
  },
});

export const { resetModal, selectUser, resetError } = usersSlice.actions;

export default usersSlice.reducer;