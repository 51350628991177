import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as levelsApi from 'api/levels';

export const levelOne = createAsyncThunk(
  'levels/levelOne',
  async (id) => {
      const response = await levelsApi.levelOne(id);
      return { data: response.data.data };
  }
);

export const getSites = createAsyncThunk(
  'levels/getSites',
  async (id) => {
      const response = await levelsApi.getSites();
      return { data: response.data.data };
  }
);

export const updatePractice = createAsyncThunk(
  'levels/updatePractice',
  async (data) => {
      const response = await levelsApi.updatePractice(data);
      return { data: response.data.data };
  }
);

export const updateComment = createAsyncThunk(
  'levels/updateComment',
  async (data) => {
      const response = await levelsApi.updateComment(data);
      return { data: response.data.data };
  }
);

export const addLevelFile = createAsyncThunk(
  'levels/addLevelFile',
  async (data) => {
      const response = await levelsApi.addFile(data);
      return { data: response.data.data };
  }
);

export const removeLevelFile = createAsyncThunk(
  'levels/removeLevelFile',
  async (data) => {
      const response = await levelsApi.removeFile(data);
      return { data: response.data.data };
  }
);

const initialState = {
  levelOneData: {},
  acPractices: [],
  iaPractices: [],
  mpPractices: [],
  pePractices: [],
  scPractices: [],
  siPractices: [],
  fileUrls: [],
  comment: '',
  sites: [],
  error: null,
  fileUploaded: false,
  loading: false,
  success: false
};

const levelsSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {
    resetFileUpload(state) {
      state.success = false;
      state.fileUploaded = false;
    },
    deleteFile(state, {payload}) {
      state.fileUrls = state.fileUrls.filter(n => n.file_id !== payload)
    },
  },
  extraReducers: {
    [levelOne.pending]: (state) => {
      state.loading = true;
    },
    [levelOne.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.levelOneData = payload.data;
      state.fileUrls = payload.data.level_file_url;
      state.acPractices = payload.data.ac_practices
      state.iaPractices = payload.data.ia_practices
      state.mpPractices = payload.data.mp_practices
      state.pePractices = payload.data.pe_practices
      state.scPractices = payload.data.sc_practices
      state.siPractices = payload.data.si_practices
      state.comment = payload.data.comment
    },
    [levelOne.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = null
    },
    [getSites.pending]: (state) => {
      state.loading = true;
    },
    [getSites.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sites = payload.data;
    },
    [updatePractice.pending]: (state) => {
      state.loading = true;
    },
    [updatePractice.fulfilled]: (state, { payload }) => {

      let type = payload.data.practice_type
      switch (type) {
        case "AC":
          const newAcPratices = state.acPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.acPractices = newAcPratices
          state.levelOneData.ac_verified = payload.data.ac_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        case "IA":
          const newIaPratices = state.iaPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.iaPractices = newIaPratices
          state.levelOneData.ia_verified = payload.data.ia_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        case "MP":
          const newMpPratices = state.mpPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.mpPractices = newMpPratices
          state.levelOneData.mp_verified = payload.data.mp_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        case "PE":
          const newPePratices = state.pePractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.pePractices = newPePratices
          state.levelOneData.pe_verified = payload.data.pe_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        case "SC":
          const newScPratices = state.scPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.scPractices = newScPratices
          state.levelOneData.sc_verified = payload.data.sc_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        case "SI":
          const newSiPratices = state.siPractices.map(elem => {
            if (elem.practice_target_id === payload.data.practice_target_id) {
              return {
                ...elem,
                status: payload.data.status,
                target_implementation_date: payload.data.target_implementation_date,
                target_implementation_date_end: payload.data.target_implementation_date_end,
                completion_date: payload.data.completion_date,
                practice_owner: payload.data.practice_owner,
                comment: payload.data.comment
    
              };
            }
            return elem;
          });
          state.siPractices = newSiPratices
          state.levelOneData.si_verified = payload.data.si_verified
          state.levelOneData.not_palnned = payload.data.not_palnned
          state.levelOneData.panned = payload.data.panned
          state.levelOneData.in_pregress = payload.data.in_pregress
          state.levelOneData.completed = payload.data.completed
          state.levelOneData.verified = payload.data.verified
          state.loading = false;
          break;
        default:
           return null
      }
    },
    [updateComment.pending]: (state) => {
      state.loading = true;
    },
    [updateComment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.comment = payload.data.comment
        
    },
    [addLevelFile.pending]: (state) => {
      state.loading = true;
    },
    [addLevelFile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fileUrls = payload.data.level_file_url
      state.success = true;
      state.fileUploaded = true;
        
    },
  },
});

export const { resetFileUpload, deleteFile } = levelsSlice.actions;


export default levelsSlice.reducer;