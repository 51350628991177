import * as Request from 'utils/Request'

export const levelOne = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${id}/levels/level_one`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const levelTwo = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${id}/levels/level_two`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const levelThree = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/sites/${id}/levels/level_three`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const getSites = async () => {
  const url = await `${Request.baseUrl}/api/v1/get_sites`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const updatePractice = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/update_practice`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  return res
 }

 export const updateComment = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/update_comment`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  return res
 }

 export const addFile = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/levels/${body.id}/add_file`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  return res
 }

 export const removeFile = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/levels/${id}/remove_file`
  const headers = await Request.authHeader()
  const res = await Request.DELETE(url, headers)
  return res
 }