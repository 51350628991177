import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as siteApi from 'api/site';

export const getSites = createAsyncThunk(
  'site/getSites',
  async () => {
      const response = await siteApi.getSites();
      return { data: response.data.data };
  }
);

export const getUnAssignSiteTokens = createAsyncThunk(
  'site/getUnAssignSiteTokens',
  async () => {
      const response = await siteApi.getUnusedSiteTokens();
      return { data: response.data.data };
  }
);

export const createSite = createAsyncThunk(
  'site/createSite',
  async (data, { rejectWithValue }) => {
      try {
        const response = await siteApi.createSite(data);
        return { data: response.data.data };
      } catch (e) {
        return rejectWithValue({ data: e.response.data });
      }
  }
);

export const updateSite = createAsyncThunk(
  'site/updateSite',
  async (data) => {
      const response = await siteApi.updateSite(data);
      return { data: response.data.data };
  }
);

export const archiveSite = createAsyncThunk(
  'site/archiveSite',
  async (id) => {
      const response = await siteApi.archiveSite(id);
      return { data: response.data.data };
  }
);

export const getSiteUsers = createAsyncThunk(
  'site/getSiteUsers',
  async (id) => {
      const response = await siteApi.siteUsers(id);
      return { data: response.data.data };
  }
);

export const getSiteId = createAsyncThunk(
  'site/getSiteId',
  async () => {
      const response = await siteApi.getSiteId();
      return { data: response.data.data };
  }
);

const initialState = {
  sites: [],
  site: {},
  siteId: null,
  siteUsers: [],
  siteToken: '',
  unAssignSiteTokens: [],
  error: null,
  loading: false,
  success: false
};

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    resetCreateSite(state) {
      state.success = false;
    },
    selectSite(state, {payload}) {
      state.site = state.sites.find(site => site.id == payload)
    },
    resetSiteError(state){
      state.error = null;
    }
  },
  extraReducers: {
    [getSites.pending]: (state) => {
      state.loading = true;
    },
    [getSites.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sites = payload.data;
    },
    [getSites.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = null
    },
    [getUnAssignSiteTokens.fulfilled]: (state, { payload }) => {
      state.unAssignSiteTokens = payload.data;
    },
    [createSite.pending]: (state) => {
      state.loading = true;
    },
    [createSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.sites = [payload.data, ...state.sites];
    },
    [createSite.rejected]: (state, {payload}) => {
      state.loading = false;
      state.error = payload.data.errors
    },
    [updateSite.pending]: (state) => {
      state.loading = true;
    },
    [updateSite.fulfilled]: (state, { payload }) => {
      state.success = true;
      const newSites = state.sites.filter(sit => sit.id != payload.data.id)
      state.loading = false;
      state.sites = [payload.data, ...newSites];
    },
    [archiveSite.pending]: (state) => {
      state.loading = true;
    },
    [archiveSite.fulfilled]: (state, { payload }) => {
      const newsites = state.sites.map(elem => {
        if (elem.id === payload.data.id) {
          return {
            ...elem,
            status: payload.data.status
          };
        }
        return elem;
      });
      state.sites = newsites
      state.success = true;
      state.loading = false;
    },
    [getSiteId.fulfilled]: (state, {payload}) => {
      state.siteId = payload.data
    },
    [getSiteUsers.pending]: (state) => {
      state.loading = true;
    },
    [getSiteUsers.fulfilled]: (state, { payload }) => {
      state.siteUsers = payload.data.site_users
      state.siteToken = payload.data.site_token
      // state.success = true;
      state.loading = false;
    },
  },
});

export const { resetCreateSite, selectSite, resetSiteError } = siteSlice.actions;


export default siteSlice.reducer;