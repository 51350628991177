/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "views/Layout/Layout";
import { useAuthDataContext } from "app/providers/AuthProvider";

const PublicRoute = ({
  component: Component,
  headingComponent: HeadingComponent,
  restricted = false,
  showHeader = true,
  showFooter = true,
  ...options
}) => {
  const { isAuthenticated } = useAuthDataContext();

  const shouldRedirect = isAuthenticated && restricted;

  const renderPublicRoute = (props) => {
    // if (isLoading) return <Spinner />;
    console.log("Public routes", isAuthenticated);
    if (shouldRedirect) return <Redirect to="/profile" />;

    return (
      <>
        <Layout showHeader={showHeader} showFooter={showFooter}>
          {HeadingComponent && <HeadingComponent {...props} />}
          <Component {...props} />
        </Layout>
      </>
    );
  };

  return <Route {...options} render={(props) => renderPublicRoute(props)} />;
};

export default PublicRoute;
