import React from "react";
import { Modal } from "semantic-ui-react";

const ContactUs = ({ open, setOpen }) => {
  return (
    <>
      <Modal
        closeIcon
        open={open}
        size={"large"}
        centered={false}
        onClose={() => setOpen(false)}
      >
        <Modal.Content>
          <div className="container">
            <h2>Contact Us</h2>
            <h3>General Inquiries and Technical Support: </h3>
            {/* <p >contact@cmmc-review.com</p> */}
            <p>
              <a
                href="#"
                target="#"
                onClick={() =>
                  (window.location.href = "mailto:contact@cmmc-review.com")
                }
              >
                contact@cmmc-review.com
              </a>
            </p>
            {/* <h3>Slice QMS Early Adopter Program: </h3>
            <p>
              <a href="https://www.slice-qms.com/" target="#">
                https://www.slice-qms.com/
              </a>
            </p> */}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ContactUs;
