import * as Request from 'utils/Request'


export const getAccountRegistrationCode = async () => {
  const url = await `${Request.baseUrl}/api/v1/registrations`
 const headers = await Request.authHeader()
 const res = await Request.GET(url, headers)
 return res
}

export const createNewAccountToken = async () => {
  const url = await `${Request.baseUrl}/api/v1/registrations/get_account_token`
 const headers = await Request.authHeader()
 const res = await Request.GET(url, headers)
 return res
}

export const createNewSiteToken = async () => {
  const url = await `${Request.baseUrl}/api/v1/registrations/get_site_token`
 const headers = await Request.authHeader()
 const res = await Request.GET(url, headers)
 return res
}

export const getsAccountRegistrationTokens = async () => {
  const url = await `${Request.baseUrl}/api/v1/registrations/account_sites_tokens`
 const headers = await Request.authHeader()
 const res = await Request.GET(url, headers)
 return res
}