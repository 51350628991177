import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as UserAPI from 'api/user';

export const register = createAsyncThunk(
    'user/register',
    async (data, { rejectWithValue }) => {
  try {
    const response = await UserAPI.register(data);
    return {data: response.data.data, message: response.data.message};
  } catch (e) {
    return rejectWithValue({ data: e.response.data });
  }
});

export const login = createAsyncThunk(
  'user/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserAPI.login(data);
      return { data: response.data.data, token: response.data.data.api_token };
    } catch (e) {
      return rejectWithValue({ data: e.response.data });
    }
  }
);

const initialState = {
  register: {
    user: {},
    registered: false,
    loading: false,
    error: null,
    message: ""
  },
  login: {
    loggedIn: false,
    loading: false,
    error: null,
    //Changed here
    // paypal_payment: false
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser(state) {
      state.login.loggedIn = false;
    },
    resetRegisterError(state) {
      state.register.error = null
    },
    resetRegisterSuccess(state) {
      state.register.registered = false
    },
    resetLoginError(state) {
      state.login.error = null
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.login.loading = true;
      state.login.error = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.login.loading = false;
      state.login.error = null;
      // state.user = payload.data; // Changed here
      localStorage.setItem('user', JSON.stringify(payload.data));
      localStorage.setItem('token', JSON.stringify(payload.token));
      localStorage.setItem('userRole', JSON.stringify(payload.data.user_role));
      state.login.loggedIn = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.login.loading = false;
      state.login.error = payload.data.errors;
    },
    [register.pending]: (state) => {
      state.register.loading = true;
    },
    [register.fulfilled]: (state, {payload}) => {
      state.register.loading = false;
      state.register.registered = true;
      state.register.message = payload.message
    },
    [register.rejected]: (state, { payload }) => {
      state.register.loading = false;
      state.register.error = payload.data.errors;
    },
  },
});
export const { logoutUser, resetRegisterError, resetRegisterSuccess, resetLoginError } = userSlice.actions;
export default userSlice.reducer;