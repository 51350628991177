import axios from "axios";

// export const baseUrl = process.env.REACT_APP_API_URL
// export const baseUrl = "https://inconsrails.ngrok.io"
//export const baseUrl = "http://68.183.30.128:3000";
// export const baseUrl = "http://localhost:3000";
// export const baseUrl = "https://54.91.229.203"
export const baseUrl = "https://cmmc-review.com"
//export const baseUrl = "http://cmmc-review.com"

//for digitalocean server cmmc
//export const baseUrl = "http://68.183.30.128";

// export const baseUrl = "https://rails-cmmc.herokuapp.com/"

export const authHeader = async () => {
  const token = await JSON.parse(localStorage.getItem("token"));
  let headers = { "Content-Type": "application/json" };
  if (token) {
    const tokenHeader = { Authorization: `Token ${token}` };
    headers = {
      ...headers,
      ...tokenHeader,
    };
  }
  return headers;
};

export const GET = async (url, headers) => {
  const response = await axios.get(url, { headers });
  return response;
};

export const POST = async (apiUrl, body, header) => {
  const response = await axios({
    method: "POST",
    url: apiUrl,
    headers: header,
    data: body,
  });
  return response;
};

export const PUT = async (apiUrl, body, header) => {
  const response = await axios({
    method: "PUT",
    url: apiUrl,
    headers: header,
    data: body,
  });
  return response;
};

export const DELETE = async (apiUrl, header) => {
  const response = await axios({
    method: "DELETE",
    url: apiUrl,
    headers: header,
  });
  return response;
};
