import * as Request from 'utils/Request'

export const getAccounts = async () => {
 const url = await `${Request.baseUrl}/api/v1/accounts`
 const headers = await Request.authHeader()
 const res = await Request.GET(url, headers)
 return res
}

export const createAccount = async (body) => {
 const url = await `${Request.baseUrl}/api/v1/accounts`
 const headers = await Request.authHeader()
 const res = await Request.POST(url, body, headers)
 return res
}

export const getUnusedAccountToken = async () => {
  const url = await `${Request.baseUrl}/api/v1/accounts/unassign_account_tokens`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
}

export const updateAccount = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/accounts/${body.id}`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  return res
 }

 export const archiveAccount = async (id, status) => {
  const url = await `${Request.baseUrl}/api/v1/accounts/${id}/archive_account?status=${status}`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }

 export const accountRegistrations = async (id) => {
  const url = await `${Request.baseUrl}/api/v1/accounts/${id}/account_registrations`
  const headers = await Request.authHeader()
  const res = await Request.GET(url, headers)
  return res
 }


 export const updateSiteLimit = async (body) => {
  const url = await `${Request.baseUrl}/api/v1/accounts/${body.id}/update_site_limit`
  const headers = await Request.authHeader()
  const res = await Request.PUT(url, body, headers)
  console.log("result result result", res)
  return res
 }