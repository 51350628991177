import React, { useState } from 'react';
import DesktopHeader from './DesktopHeader';
import Footer from './Footer';

// eslint-disable-next-line react/prop-types
const Layout = ({ showHeader, showFooter, children }) => {

  return (
    <>
      {showHeader && <DesktopHeader />}
      {children}
      {showFooter && <Footer />}
    </>
  );
};

export default Layout;