import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import Spinner from "./../components/Spinners/Spinner";
import renderRoutes from "./routing/renderRoutes";
import AuthProvider from "./providers/AuthProvider";
import store from "./store";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./bootstrap.css";
import "./App.scss";

const history = createBrowserHistory();

const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Provider store={store}>
        <AuthProvider>
          <Router history={history}>
            {/* <ErrorBoundary> */}
            <ToastContainer autoClose={2000} />
            <Switch>{renderRoutes()}</Switch>
            {/* </ErrorBoundary> */}
          </Router>
        </AuthProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
